import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            // title={data.site.siteMetadata.title}
            title="Tobacco 101 &amp; Vape"
            meta={[
              { name: 'title', content: 'Tobacco 101 & Vape' },
              { name: 'description', content: "Mississippi's premier tobacco, vaporizer, and herbal remedy chain." },
              { name: 'keywords', content: 'vape, tupelo, north, mississippi, hookah, starkville, oxford, kratom, papers, tobacco' },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: 'https://tobacco101vape.com/' },
              { property: 'og:title', content: 'Tobacco 101 & Vape' },
              { property: 'og:description', content: "Mississippi's premier tobacco, vaporizer, and herbal remedy chain." },
              { property: 'og:image', content: 'https://tobacco101vape.com/metabg.png' },
              { property: 'twitter:card', content: 'summary_large_image' },
              { property: 'twitter:url', content: 'https://tobacco101vape.com/' },
              { property: 'twitter:title', content: 'Tobacco 101 & Vape' },
              { property: 'twitter:description', content: "Mississippi's premier tobacco, vaporizer, and herbal remedy chain." },
              { property: 'twitter:image', content: 'https://tobacco101vape.com/metabg.png' },
              { name: 'msapplication-TileColor', content: '#2b5797' },
              { name: 'theme-color', content: '#ffffff'}
            ]}
            link={[
              { rel: 'apple-touch-icon', sizes: '180x180', href: 'https://tobacco101vape.com/apple-touch-icon.png' },
              { rel: 'icon', type: 'image/png', sizes: '32x32', href: 'https://tobacco101vape.com/favicon-32x32.png' },
              { rel: 'icon', type: 'image/png', sizes: '16x16', href: 'https://tobacco101vape.com/favicon-16x16.png' },
              { rel: 'manifest', href: 'https://tobacco101vape.com/site.webmanifest' },
              { rel: 'mask-icon', href: 'https://tobacco101vape.com/safari-pinned-tab.svg', color: '#d63e3e' }
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
